<template>
  <aside
    :class="{ active: !!lightbox.active, zoom: lightbox.zoom }"
    class="lightbox theme-navy"
  >
    <LazyAtomsButton
      class="lightbox-close"
      type="close"
      :appearance="['outline']"
      :icon="{ name: 'x-mark' }"
      icon-only
      @click="lightbox.close()"
    />
    <LazyAtomsButton
      v-if="lightbox.images.length"
      class="lightbox-prev"
      :appearance="['outline']"
      :icon="{ name: 'chevron-left' }"
      plain
      @click="lightbox.prev()"
    />
    <div class="lightbox-content container" @click="lightbox.toggleZoom()">
      <transition
        :key="(lightbox.value && lightbox.value.id) || lightbox.value"
        name="image"
      >
        <LazyAtomsImage
          v-if="lightbox.value"
          :image="lightbox.value"
          class="lightbox-content-item lightbox-image"
        />
      </transition>
    </div>
    <LazyAtomsButton
      v-if="lightbox.images.length"
      class="lightbox-next"
      :icon="{ name: 'chevron-right' }"
      :appearance="['outline']"
      plain
      @click="lightbox.next()"
    />
  </aside>
</template>

<script lang="ts" setup>
const { lightbox } = useShared()

watchEffect(() => {
  handleEventListener<'keyup'>(lightbox.value, 'keyup', (e) => {
    switch (e.code) {
      case 'ArrowUp':
      case 'KeyW':
        lightbox.zoom = true
        break
      case 'ArrowDown':
      case 'KeyS':
        lightbox.zoom = false
        break
      case 'ArrowRight':
      case 'KeyD':
        lightbox.next()
        break
      case 'ArrowLeft':
      case 'KeyA':
        lightbox.prev()
        break
      case 'KeyZ':
        lightbox.toggleZoom()
        break
      case 'Escape':
        lightbox.close()
        break
    }
  })
})
</script>

<style lang="scss">
.image-enter,
.image-leave-to {
  transform: scale(0.5) translateY(100vh);
  opacity: 0;
}

.lightbox {
  @include position(0, 0, 0, 0, fixed);
  z-index: 2000;

  display: flex;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  padding: 3vmin;
  overflow: hidden;
  overflow: scroll;

  &::before {
    position: fixed;
    inset: 0;
    background: var(--bg);
    content: '';
    opacity: 0.9;
  }

  transition: opacity 0.5s, visibility 0.5s;

  &:not(.active) {
    visibility: hidden;
    opacity: 0;

    pointer-events: none;
  }

  &.zoom {
    .lightbox-content-item {
      width: auto;
      height: auto;

      cursor: zoom-out;
    }
  }

  &-content {
    position: relative;
    margin: 0 auto;
    z-index: 100;

    width: 100%;
    height: 100%;

    text-align: center;

      cursor: zoom-in;

    &-item {
      position: relative;
      z-index: 100;

      width: 100%;
      height: 100%;

      text-align: center;

      pointer-events: none;

      cursor: zoom-in;

      transition: 0.5s;
    }
  }
  &-image.image {
    width: 100%;
    height: 100%;
    object-fit: contain;

    transition: 0.5s;
  }

  &-close.nuxt-button.button {
    position: fixed;
    inset: 1.5rem 1.5rem auto auto;
    z-index: 900;

    transition: 0.5s;
  }

  &.closed {
    .sidebar-close {
      transform: scale(0);
    }
  }

  .lightbox {
    &-prev,
    &-next {
      position: fixed;
      height: 100vh;
      top: 0;
      bottom: 0;
      font-size: 2rem;
      padding: max(1vw, 1rem);

      .nuxt-button-icon-hover {
        display: none;
      }
      &:hover,
      &:focus,
      &:active {
        background: rgba($black, 0.1);
      }
    }
    &-prev {
      left: 0;
    }
    &-next {
      right: 0;
    }
  }
}
</style>
